import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "my-4 px-10 py-1" }
const _hoisted_2 = { class: "flex space-x-4 mb-3" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "grid grid-cols-12 gap-4 mb-5 relative" }
const _hoisted_5 = { class: "col-span-3 2xl:col-span-2" }
const _hoisted_6 = { class: "flex flex-col space-y-3" }
const _hoisted_7 = { class: "form-group__full inputfield" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "space-y-3" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "col-span-9 2xl:col-span-8" }
const _hoisted_12 = { class: "flex flex-col space-y-3" }
const _hoisted_13 = { class: "box h-24 w-full bg-cafe-dark relative overflow-hidden" }
const _hoisted_14 = { class: "flex flex-wrap space-y-3 xl:space-y-0 xl:space-x-3" }
const _hoisted_15 = { class: "box h-52 w-full flex-1 xl:w-9/12 bg-cafe-dark relative overflow-hidden" }
const _hoisted_16 = { class: "box h-52 w-full xl:w-3/12 bg-cafe-dark relative overflow-hidden" }
const _hoisted_17 = { style: { minHeight: '31rem' } }
const _hoisted_18 = ["id"]
const _hoisted_19 = ["id"]
const _hoisted_20 = ["id"]
const _hoisted_21 = ["id"]
const _hoisted_22 = ["id"]
const _hoisted_23 = ["id"]
const _hoisted_24 = { class: "col-span-12 2xl:col-span-2" }
const _hoisted_25 = { class: "flex flex-wrap 2xl:flex-col 2xl:space-y-3" }
const _hoisted_26 = { class: "box bg-cafe-dark relative flex-1 overflow-hidden" }
const _hoisted_27 = {
  class: /*@__PURE__*/_normalizeClass([
              'box bg-cafe-dark relative overflow-hidden text-gray-400 space-y-1 flex-1 ml-3 2xl:ml-0',
            ])
}
const _hoisted_28 = { class: "box bg-cafe-dark relative space-y-2 mt-3 xl:mt-0 xl:ml-3 2xl:ml-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ol", _hoisted_2, [
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Home")
          ])),
          _: 1
        })
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/facilities-monitoring" }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode(" Facilities Monitoring ")
          ])),
          _: 1
        })
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: `/facilities-monitoring/${$setup.route.params.facility_id}`
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.convertFacilityIdToName($setup.route.params.facility_id)), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_el_select, {
              class: "",
              placeholder: "",
              modelValue: $setup.facilityValue,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.facilityValue) = $event)),
              onChange: $setup.handleChangeFacilityValue
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.facilities, (item) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: item.Value,
                    label: item.Name,
                    value: item.Value
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _createElementVNode("img", {
            class: "object-cover object-fill object-center h-52 border-2",
            src: `${
              $setup.UrlFacilities
                ? $setup.UrlFacilities
                : require('@/assets/images/default-placeholder.png')
            }`,
            alt: "default-placeholder"
          }, null, 8, _hoisted_8),
          _createElementVNode("div", null, [
            _createElementVNode("ul", _hoisted_9, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values($setup.HASH), (value) => {
                return (_openBlock(), _createElementBlock("li", { key: value }, [
                  _createElementVNode("button", {
                    type: "button",
                    class: _normalizeClass([
                    'w-full h-full text-left box rounded-md px-3 py-2',
                    $setup.route.hash === `#${value}`
                      ? 'bg-blue-500 shadow-none'
                      : 'bg-cafe-dark',
                  ]),
                    onClick: _withModifiers(($event: any) => ($setup.handleTabClick(value)), ["prevent"])
                  }, _toDisplayString($setup.TAB[value.toLocaleUpperCase()]), 11, _hoisted_10)
                ]))
              }), 128))
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createVNode($setup["TheStatsBar"], { dataFacilitySummaryInfo: $setup.dataFacilitySummaryInfo }, null, 8, ["dataFacilitySummaryInfo"]),
            ($setup.isLoading)
              ? (_openBlock(), _createBlock($setup["BaseSpinner"], { key: 0 }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createVNode($setup["TheSystemOperationalDiagram"], { dataFacilitySummaryInfo: $setup.dataFacilitySummaryInfo }, null, 8, ["dataFacilitySummaryInfo"]),
              ($setup.isLoading)
                ? (_openBlock(), _createBlock($setup["BaseSpinner"], { key: 0 }))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_16, [
              _cache[5] || (_cache[5] = _createElementVNode("h1", { class: "text-left text-sm font-semibold" }, "Load Supply", -1)),
              (!$setup.loadingFacilitySummaryInfo)
                ? (_openBlock(), _createBlock($setup["DoughnutChart"], {
                    key: 0,
                    class: "h-40 w-40 pt-1",
                    data: $setup.dataPiedChart,
                    legendStyle: { position: 'right', fontSize: 12, padding: 10 }
                  }, null, 8, ["data"]))
                : _createCommentVNode("", true),
              ($setup.isLoading)
                ? (_openBlock(), _createBlock($setup["BaseSpinner"], { key: 1 }))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            ($setup.route.hash === `#${$setup.HASH.OVERVIEW}`)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "box w-full bg-cafe-dark relative",
                  style: { height: '31rem' },
                  id: $setup.HASH.OVERVIEW
                }, [
                  _createVNode($setup["TheOverview"], {
                    dataOverviewGeneration: $setup.dataOverviewGeneration,
                    dataOverviewConsumption: $setup.dataOverviewConsumption,
                    dataOverviewEnergyStorage: $setup.dataOverviewEnergyStorage
                  }, null, 8, ["dataOverviewGeneration", "dataOverviewConsumption", "dataOverviewEnergyStorage"])
                ], 8, _hoisted_18))
              : ($setup.route.hash === `#${$setup.HASH.SYSTEMS_INFORMATION}`)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "box w-full bg-cafe-dark relative p-0 overflow-auto",
                    id: $setup.HASH.SYSTEMS_INFORMATION
                  }, [
                    _createVNode($setup["TheSystemsInformation"])
                  ], 8, _hoisted_19))
                : ($setup.route.hash === `#${$setup.HASH.ENERGY_MONITORING}`)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      class: "box h-full w-full bg-cafe-dark relative",
                      id: $setup.HASH.ENERGY_MONITORING
                    }, [
                      _createVNode($setup["TheEnergyMonitoring"], {
                        dataPerformanceOverview: $setup.dataPerformanceOverview,
                        dataPerformanceOverview7Day: $setup.dataPerformanceOverview7Day,
                        dataPerformanceOverview1Month: $setup.dataPerformanceOverview1Month,
                        dataPerformanceOverviewMTD: $setup.dataPerformanceOverviewMTD,
                        dataPerformanceOverviewYTD: $setup.dataPerformanceOverviewYTD,
                        dataLoadPerformance: $setup.dataLoadPerformance,
                        dataLoadPerformance7Day: $setup.dataLoadPerformance7Day,
                        dataSystemPerformance: $setup.dataSystemPerformance,
                        dataSystemPerformance7Day: $setup.dataSystemPerformance7Day,
                        onHandleChangeTabOverviewEnergy: $setup.handleChangeTabOverviewEnergy,
                        onHandleChangeOneDatePicker: $setup.handleChangeOneDatePicker,
                        onHandleChangeOneWeekPicker: $setup.handleChangeOneWeekPicker,
                        onHandleChangeOneMonthPicker: $setup.handleChangeOneMonthPicker
                      }, null, 8, ["dataPerformanceOverview", "dataPerformanceOverview7Day", "dataPerformanceOverview1Month", "dataPerformanceOverviewMTD", "dataPerformanceOverviewYTD", "dataLoadPerformance", "dataLoadPerformance7Day", "dataSystemPerformance", "dataSystemPerformance7Day"])
                    ], 8, _hoisted_20))
                  : (
                $setup.route.hash === `#${$setup.HASH.OPERATIONS_AND_MAINTENANCE_ALARMS}`
              )
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 3,
                        id: $setup.HASH.OPERATIONS_AND_MAINTENANCE_ALARMS
                      }, [
                        _createVNode($setup["TheOperationsMaintenanceAlarms"], { dataTable: $setup.dataOperationsAlarm }, null, 8, ["dataTable"])
                      ], 8, _hoisted_21))
                    : ($setup.route.hash === `#${$setup.HASH.CARBON_EMISSIONS_BY_SCOPE}`)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 4,
                          class: "box h-full w-full bg-cafe-dark relative",
                          id: $setup.HASH.CARBON_EMISSIONS_BY_SCOPE
                        }, [
                          _createVNode($setup["TheCarbonEmissionsByScope"], {
                            dataCarbonEmissionByScope1: $setup.dataCarbonEmissionByScope1,
                            dataCarbonEmissionByScope3: $setup.dataCarbonEmissionByScope3,
                            dataCarbonTargetByScope1: $setup.dataCarbonTargetByScope1
                          }, null, 8, ["dataCarbonEmissionByScope1", "dataCarbonEmissionByScope3", "dataCarbonTargetByScope1"])
                        ], 8, _hoisted_22))
                      : ($setup.route.hash === `#${$setup.HASH.RENEWABLE_ENERGY_PERFORMANCE}`)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 5,
                            class: "box h-full w-full bg-cafe-dark relative",
                            id: $setup.HASH.RENEWABLE_ENERGY_PERFORMANCE
                          }, [
                            _createVNode($setup["TheRenewableEnergyPerformance"], {
                              dataScopeTwoEnergyLoad: $setup.dataScopeTwoEnergyLoad,
                              dataScopeTwoRenewableEnergy: $setup.dataScopeTwoRenewableEnergy,
                              dataScopeTwoLineMarker: $setup.dataScopeTwoLineMarker,
                              dataScopeTwoEmissionTarget: $setup.dataScopeTwoEmissionTarget,
                              dataMaxPercentChart: $setup.dataMaxPercentChart,
                              isLoadingScopeTwoEnergyLoad: $setup.isLoadingScopeTwoEnergyLoad
                            }, null, 8, ["dataScopeTwoEnergyLoad", "dataScopeTwoRenewableEnergy", "dataScopeTwoLineMarker", "dataScopeTwoEmissionTarget", "dataMaxPercentChart", "isLoadingScopeTwoEnergyLoad"])
                          ], 8, _hoisted_23))
                        : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_24, [
        _createElementVNode("div", _hoisted_25, [
          _createElementVNode("div", _hoisted_26, [
            _createVNode($setup["TheWeatherForecast"], { data: $setup.dataPvForest }, null, 8, ["data"]),
            ($setup.isLoading)
              ? (_openBlock(), _createBlock($setup["BaseSpinner"], { key: 0 }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_27, [
            _createVNode($setup["TheGreenHouseGasesAvoided"], { dataGreenHouseGases: $setup.dataGreenHouseGases }, null, 8, ["dataGreenHouseGases"]),
            ($setup.isLoading)
              ? (_openBlock(), _createBlock($setup["BaseSpinner"], { key: 0 }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_28, [
            _cache[9] || (_cache[9] = _createElementVNode("h1", { class: "text-left text-sm font-semibold" }, "Your Green Options", -1)),
            _createVNode(_component_router_link, {
              to: "/setting-information/your-green-options/select-standard",
              class: _normalizeClass(_ctx.styles.card)
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode(" Buy RECs ")
              ])),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_router_link, {
              to: "/",
              class: _normalizeClass(_ctx.styles.card)
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode(" Offset your Carbon Footprint ")
              ])),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_router_link, {
              to: "/",
              class: _normalizeClass(_ctx.styles.card)
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode(" Offset Green Options ")
              ])),
              _: 1
            }, 8, ["class"])
          ])
        ])
      ])
    ])
  ]))
}